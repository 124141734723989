const languageTypes = {
    ANY: '',
    AR: 'ar',
    DE: 'de',
    EN: 'en',
    ES: 'es',
    FR: 'fr',
    IT: 'it',
    JA: 'ja',
    PT: 'pt',
    SV: 'sv',
    ZH_CN: 'zh-cn',
    ZH_TW: 'zh-tw',
};

export default languageTypes;
