import React, { useContext } from 'react';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Panel from 'js/components/panel/panel';
import SectionTitle from 'js/components/section-title/section-title';
import { unsupportedYoutubeLangs } from 'js/constants';
import PageSection from '../page-section/page-section';
import Channels from './channels/channels';
import ContextDetailContext from '../../contexts/context-detail.context';

function Wrapper({ children }) {
    return (
        <PageSection
            title="Channels"
            description={`Select the channels and seats that this context will 
                    be available on.`}
        >
            {children}
        </PageSection>
    );
}

export default function ChannelsSection({
    onSetChannels,
    onCreateDeal,
    onActivateDeal,
    onSetChannelGeotargets,
}) {
    const {
        context: {
            current: { is_video: isVideo, language },
        },
        channels,
        page: { isReadOnly },
    } = useContext(ContextDetailContext);

    const environmentChannels = channels.filter(
        (item) =>
            (item.is_video === isVideo || item.is_video === null) &&
            (!unsupportedYoutubeLangs.includes(language) ||
                !/youtube/i.test(item.name)),
    );

    const directChannels = environmentChannels.filter(
        (item) => !item.deal_providers.length,
    );

    const dealChannels = environmentChannels.filter(
        (item) => item.deal_providers.length,
    );

    if (!directChannels.length && !dealChannels.length) {
        return (
            <Wrapper>
                <Box margin={['large', 0, 0]}>
                    <Alert theme="empty">
                        <p>There are no channels with seats on this account.</p>
                        <p>
                            At least one seat is required to be able to activate
                            this context. To add a seat to this account, go to
                            Settings &gt; Channels, select the channel that you
                            want to add a seat to and click the ‘Add New Seat’
                            button.
                        </p>
                    </Alert>
                </Box>
            </Wrapper>
        );
    }

    return (
        <Wrapper>
            {directChannels.length > 0 && (
                <Box margin={['large', 0, 0]}>
                    <Panel bordered>
                        <Box padding={['base', 'base', 0]}>
                            <Box padding={['small', 0, 'base']}>
                                <SectionTitle
                                    info={
                                        <p>
                                            Select the channels and seats that
                                            you would like this context to be
                                            available on. You will be able to
                                            select this context in the partner
                                            UI for selected seats when setting
                                            up a campaign.
                                        </p>
                                    }
                                >
                                    Direct Channels
                                </SectionTitle>
                            </Box>

                            <Channels
                                channels={directChannels}
                                isDirect
                                isReadOnly={isReadOnly}
                                onSetChannels={onSetChannels}
                            />
                        </Box>
                    </Panel>
                </Box>
            )}

            {dealChannels.length > 0 && (
                <Box margin={['large', 0, 0]}>
                    <Panel bordered>
                        <Box padding={['base', 'base', 0]}>
                            <Box padding={['small', 0, 'base']}>
                                <SectionTitle
                                    info={
                                        <p>
                                            Select the channels and seats that
                                            you would like to generate deal IDs
                                            for.
                                        </p>
                                    }
                                >
                                    Deal ID Channels
                                </SectionTitle>
                            </Box>

                            <Channels
                                channels={dealChannels}
                                isReadOnly={isReadOnly}
                                onCreateDeal={onCreateDeal}
                                onActivateDeal={onActivateDeal}
                                onSetChannelGeotargets={onSetChannelGeotargets}
                                onSetChannels={onSetChannels}
                            />
                        </Box>
                    </Panel>
                </Box>
            )}
        </Wrapper>
    );
}
