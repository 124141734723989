import { faChevronDown } from '@fortawesome/free-solid-svg-icons';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { showToast } from 'js/components/toast/toast';
import React, { useContext, useState } from 'react';
import { copyContext, RuleSet } from 'js/utils';
import entityTypes from 'js/enums/entity-types.enum';
import sectionTypes from 'js/enums/section-types.enum';
import ActionBar from 'js/components/action-bar/action-bar';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import ErrorBar from 'js/components/error-bar/error-bar';
import LoadingLayer from 'js/components/loading-layer/loading-layer';
import DropdownDivider from 'js/components/dropdown-menu/divider';
import DropdownMenu from 'js/components/dropdown-menu/dropdown-menu';
import DropdownButton from 'js/components/dropdown-menu/button';
import DropdownTitle from 'js/components/dropdown-menu/title';
import FavoritesButton from 'js/components/button/favorites-button';
import Spinner from 'js/components/spinner/spinner';
import { origins, unsupportedYoutubeLangs } from 'js/constants';
import ChannelsSection from '../channels-section/channels-section';
import DetailsSection from '../details-section/details-section';
import MatchedPagesSection from '../matched-items-sections/matched-pages-section/matched-pages-section';
import OptimizeSection from '../optimize-section/optimize-section';
import SectionNavigation from '../section-navigation/section-navigation';
import SectionWaypoint from '../section-waypoint/section-waypoint';
import ContextDetailContext from '../../contexts/context-detail.context';
import MatchedVideosSection from '../matched-items-sections/matched-videos-section/matched-videos-section';
import DimensionsSection from '../dimensions-section/dimensions-section';

function ContextCopyDropdown() {
    const {
        context: {
            current: currentContext,
            saved: savedContext,
            hasUnsavedChanges,
            setHasUnsavedChanges,
        },
    } = useContext(ContextDetailContext);
    const [showMenu, setShowMenu] = useState(false);

    if (!hasUnsavedChanges) {
        return (
            <Button theme="outline" onClick={() => copyContext(currentContext)}>
                Create Copy
            </Button>
        );
    }
    return (
        <DropdownMenu
            content={
                <ul>
                    <DropdownTitle>
                        This context has unsaved changes that will be discarded
                        when creating a copy.
                    </DropdownTitle>
                    <DropdownDivider />
                    <DropdownButton
                        onClick={async () => {
                            await setHasUnsavedChanges(false);
                            copyContext(currentContext);
                        }}
                    >
                        Including unsaved changes
                    </DropdownButton>
                    <DropdownButton
                        onClick={async () => {
                            await setHasUnsavedChanges(false);
                            copyContext(savedContext);
                        }}
                    >
                        Excluding unsaved changes
                    </DropdownButton>
                </ul>
            }
            onHide={() => setShowMenu(false)}
            showMenu={showMenu}
            placement="bottom-start"
            width="fixed"
        >
            <Button
                theme="outline"
                suffix={<FontAwesomeIcon icon={faChevronDown} />}
                onClick={() => setShowMenu(true)}
                active={showMenu}
            >
                Create Copy
            </Button>
        </DropdownMenu>
    );
}

function ContextDetailActionBar({ toggleDeleteWarningModal }) {
    const {
        page: { isLoading: loading, isReadOnly },
        context: {
            current,
            current: { id: contextId, is_favourite: isFavorite },
        },
        favorites: { isLoading: isFavoriteLoading, toggle: onToggleFavorite },
    } = useContext(ContextDetailContext);

    if (!contextId) return null;

    return (
        <ActionBar>
            {isReadOnly ? (
                <Button
                    theme="outline"
                    onClick={() => {
                        copyContext(current, { emptyCampaign: true });
                    }}
                >
                    Create Copy
                </Button>
            ) : (
                [
                    <FavoritesButton
                        key="favorite"
                        isFavorite={isFavorite}
                        isLoading={loading || isFavoriteLoading}
                        onToggleFavorite={() =>
                            onToggleFavorite(
                                entityTypes.CONTEXTS,
                                contextId,
                                !isFavorite,
                            )
                        }
                    />,
                    <ContextCopyDropdown key="copy" />,

                    <Button
                        key="delete"
                        theme="outline"
                        onClick={toggleDeleteWarningModal}
                    >
                        Delete
                    </Button>,
                ]
            )}
        </ActionBar>
    );
}

export default function ContextDetailBody({
    setDimensionWarning,
    setDimensionChanges,
    setDimensionWarningSuccess,
    setVideoWarning,
    setVideoWarningSuccess,
    toggleAdvertiserModal,
    toggleCampaignModal,
    toggleDeleteWarningModal,
}) {
    const {
        page: {
            isLoading: loading,
            hasError: error,
            isSaving: saving,
            isReadOnly,
        },
        pageSections: { exists: sectionExists },
        channels,
        context: {
            id: contextId,
            current: context,
            current: { is_video: isVideo },
            setAdvertiser,
            setCampaign,
            setLanguage,
            setIsVideo,
            setChannels,
            setRules,
            updateAttr: getOnChangeAttr,
        },
        deals: {
            create: createDeal,
            activate: activateDeal,
            setChannelGeotargets,
        },
        topics: { groups: topicGroups },
        logoGroups,
        optimize: {
            current: {
                is_active: optimizeIsActive,
                controls: optimizeControls,
                campaigns: optimizeCampaigns,
                recommendations: optimizeRecommendations,
                target_metric: optimizeTargetMetric,
            },
            setIsActive: setOptimizeIsActive,
            setTarget: setOptimizeTarget,
            setControls: setOptimizeControls,
            setCampaigns: setOptimizeCampaigns,
        },
        eventHierarchy: { data: eventHierarchy, load: loadEventHierarchy },
        origins: { isActiveOrigin },
        matchedPages: {
            isLazyWaiting: isMatchedPagesLazyWaiting,
            needsReload: needsMatchedPagesReload,
            load: getMatchedPages,
        },
        matchedVideos: {
            isLazyWaiting: isMatchedVideosLazyWaiting,
            needsReload: needsMatchedVideosReload,
            load: getMatchedVideos,
        },
        favorites: { togglingError: togglingFavoriteError },
    } = useContext(ContextDetailContext);

    const activeOrigins = origins.filter(isActiveOrigin);

    const changeLanguage = (value) => {
        const newLanguage = value;

        if (isVideo) {
            if (unsupportedYoutubeLangs.includes(newLanguage)) {
                const ytChannelIds = channels
                    .filter((cn) => /youtube/i.test(cn.name))
                    .map((cnn) => cnn.id);

                setChannels(
                    context.channels.filter((c) => !ytChannelIds.includes(c)),
                );
            }
            // under the hood topics/logos are always in EN, no need to reset rules
            return setLanguage(newLanguage);
        }

        const changes = [];
        changes.push(
            ...activeOrigins
                .filter((item) => !item.is_video)
                .map((item) => item.name),
        );
        if (changes.length) {
            setDimensionWarning(true);
            setDimensionChanges(changes);
            setDimensionWarningSuccess(() => () => {
                setLanguage(newLanguage);
                setRules(new RuleSet().rules);
                setDimensionWarning(false);
            });

            return null;
        }
        return setLanguage(newLanguage);
    };

    const changeContentType = (value) => {
        if (
            context.channels.length ||
            activeOrigins.length ||
            optimizeIsActive
        ) {
            setVideoWarning(true);
            setVideoWarningSuccess(() => () => {
                setIsVideo(value);
                setVideoWarning(false);
            });

            return null;
        }

        return setIsVideo(value);
    };

    const savingMessage = contextId ? 'Saving Context' : 'Creating Context';

    if (error) return <ErrorBar action="Retry Loading Page" />;

    if (loading)
        return (
            <Box padding="large">
                <Spinner
                    size="large"
                    message={contextId ? 'Loading Context' : 'Loading'}
                    color={['gray', 'dark']}
                    isCentered
                />
            </Box>
        );
    if (Object.keys(togglingFavoriteError).length) {
        showToast(
            'There Was An Unexpected Error',
            <p>
                It was not possible to{' '}
                {togglingFavoriteError.isFavorite ? 'favorite' : 'unfavorite'}{' '}
                {togglingFavoriteError.title}. Please try again in a few
                moments.
            </p>,
            5,
            'danger',
        );
    }

    return (
        <>
            {saving && <LoadingLayer message={savingMessage} />}

            <ContextDetailActionBar
                toggleDeleteWarningModal={toggleDeleteWarningModal}
            />

            <SectionNavigation />

            <SectionWaypoint sectionId={sectionTypes.DETAILS}>
                <DetailsSection
                    onChangeContentType={changeContentType}
                    onCreateAdvertiser={toggleAdvertiserModal}
                    onChangeAdvertiser={setAdvertiser}
                    onCreateCampaign={toggleCampaignModal}
                    onChangeCampaign={setCampaign}
                    onChangeName={getOnChangeAttr('name')}
                    onChangeLanguage={changeLanguage}
                />
            </SectionWaypoint>

            {sectionExists(sectionTypes.CHANNELS) && (
                <SectionWaypoint sectionId={sectionTypes.CHANNELS}>
                    <ChannelsSection
                        onSetChannels={setChannels}
                        onCreateDeal={createDeal}
                        onActivateDeal={activateDeal}
                        onSetChannelGeotargets={setChannelGeotargets}
                    />
                </SectionWaypoint>
            )}

            <SectionWaypoint sectionId={sectionTypes.DIMENSIONS}>
                <DimensionsSection />
            </SectionWaypoint>

            {sectionExists(sectionTypes.OPTIMIZE) && (
                <SectionWaypoint sectionId={sectionTypes.OPTIMIZE}>
                    <OptimizeSection
                        isReadOnly={isReadOnly}
                        isVideo={isVideo}
                        isActive={optimizeIsActive}
                        targetMetric={optimizeTargetMetric}
                        controls={optimizeControls}
                        campaigns={optimizeCampaigns}
                        recommendations={optimizeRecommendations}
                        rules={context.rules}
                        eventHierarchy={eventHierarchy}
                        onToggleActive={() =>
                            setOptimizeIsActive(!optimizeIsActive)
                        }
                        onChangeTarget={setOptimizeTarget}
                        onChangeControls={setOptimizeControls}
                        onChangeCampaigns={setOptimizeCampaigns}
                        onChangeRules={setRules}
                        onLoadEventHierarchy={loadEventHierarchy}
                        topicGroups={topicGroups}
                        logoGroups={logoGroups}
                        language={context.language}
                    />
                </SectionWaypoint>
            )}

            {sectionExists(sectionTypes.MATCHED_PAGES) && (
                <SectionWaypoint
                    sectionId={sectionTypes.MATCHED_PAGES}
                    onEnter={() =>
                        isMatchedPagesLazyWaiting &&
                        !needsMatchedPagesReload &&
                        getMatchedPages()
                    }
                >
                    <MatchedPagesSection />
                </SectionWaypoint>
            )}

            {sectionExists(sectionTypes.MATCHED_VIDEOS) && (
                <SectionWaypoint
                    sectionId={sectionTypes.MATCHED_VIDEOS}
                    onEnter={() =>
                        isMatchedVideosLazyWaiting &&
                        !needsMatchedVideosReload &&
                        getMatchedVideos()
                    }
                >
                    <MatchedVideosSection />
                </SectionWaypoint>
            )}
        </>
    );
}
