import React, { useEffect, useState } from 'react';
import {
    AbortError,
    api,
    cleanUrl,
    createPresetUrl,
    reloadPage,
    RuleSet,
    validUrl,
} from 'js/utils';
import Alert from 'js/components/alert/alert';
import Box from 'js/components/box/box';
import Button from 'js/components/button/button';
import Container from 'js/components/container/container';
import Emblem from 'js/components/emblem/emblem';
import Form from 'js/components/form/form';
import Col from 'js/components/grid/column';
import Row from 'js/components/grid/row';
import Input from 'js/components/input/input';
import Panel from 'js/components/panel/panel';
import SectionTitle from 'js/components/section-title/section-title';
import Section from 'js/components/section/section';
import Spinner from 'js/components/spinner/spinner';
import Text from 'js/components/text/text';
import Video from 'js/components/video/video';
import { useHistory, useParams } from 'react-router-dom';
import { useLimitList } from 'js/hooks';
import styles from '../../discover.module.scss';

const TIMEOUT_LIMIT = 120000; // 2 minutes

const ExploreForm = ({
    inputUrl,
    processedUrl,
    setUrl,
    children,
    errorMessage,
}) => {
    const history = useHistory();

    const cleanedInput = cleanUrl(inputUrl);
    const isButtonDisabled =
        (!inputUrl && processedUrl) ||
        !!(processedUrl && errorMessage) ||
        !!(inputUrl && cleanedInput === processedUrl);

    const onSubmit = () => {
        if (inputUrl) {
            history.push(
                `/discover/video/${encodeURIComponent(cleanedInput)}/`,
            );
        } else {
            history.push('/discover/video/');
        }
    };

    return (
        <Container size="medium">
            <Box padding={['base', 0, 0]} margin={[0, 0, 'large']}>
                <Form onSubmit={onSubmit}>
                    <Row gutter="small">
                        <Col>
                            <Input
                                focusOnShow
                                value={inputUrl}
                                onChange={(e) => setUrl(e.target.value)}
                                prefix="URL"
                                errorMessage={<p>{errorMessage}</p>}
                                hasError={!!errorMessage}
                            />
                        </Col>
                        <Col span={12} spanSm="auto">
                            <Button type="submit" disabled={isButtonDisabled}>
                                Explore Video
                            </Button>
                        </Col>
                    </Row>
                </Form>
            </Box>
            {children}
        </Container>
    );
};

const StartMessage = () => (
    <Alert
        theme="empty"
        title="Enter a Video URL above to learn how 4D understands it"
    >
        <p>
            You will be shown topics and brand groups that are relevant to the
            content of the video.
        </p>
    </Alert>
);

const GenericErrorMessage = ({ url, onRetry }) => (
    <Alert
        theme="danger"
        title="Unable to access video"
        actionItems={<Button onClick={onRetry}>Retry</Button>}
    >
        <p>4D was unable to access a video with the URL &apos;{url}&apos;.</p>
        <p>
            Please check that the URL is correct and is accessible without
            having to login before trying again.
        </p>
    </Alert>
);

const TimeoutAlertMessage = ({ onRetry }) => (
    <Alert
        title="You've been waiting a while..."
        actionItems={<Button onClick={onRetry}>Continue waiting</Button>}
    >
        <p>
            It takes time to analyze URLs, and we need more time to handle your
            request.
        </p>
        <span>
            Instead of waiting, you can create contexts, view insights reports,
            or see more about our recent feature releases. We will be analyzing
            your URL behind the scenes. You can revisit this page at any time to
            check how things are going.
        </span>
        <p>If you would prefer to wait here, click the button below.</p>
        <p>Thanks for your patience.</p>
    </Alert>
);

const TaskLoadingMessage = ({ task }) => {
    const {
        state,
        wait_time: waitTimeSeconds,
        queue_position: queuePosition,
    } = task;
    const waitTimeMinutes = Math.ceil(waitTimeSeconds / 60);
    const taskIsInValudProgress =
        (state === 'Todo' || state === 'Processing') &&
        waitTimeSeconds / 60 > 0;
    const waitTime = taskIsInValudProgress
        ? `This should take about ${waitTimeMinutes} more ${
              waitTimeMinutes > 1 ? 'minutes' : 'minute'
          }.`
        : "This shouldn't take too much longer.";
    const aheadInQueue = queuePosition - 1;
    const queueLength = aheadInQueue
        ? `There ${aheadInQueue > 1 ? 'are' : 'is'} ${aheadInQueue} ${
              aheadInQueue > 1 ? 'people' : 'person'
          } ahead of you in the queue.`
        : 'You are at the front of the queue.';
    return (
        <>
            <Text align="center">{waitTime}</Text>
            {!!queuePosition && (
                <Text size="base" align="center">
                    {queueLength}
                </Text>
            )}
        </>
    );
};

const TopicCard = ({ topic }) => {
    const { name, group_name: groupName, group_logo: logo } = topic || {};
    return (
        <Box margin={[0, 0, 'base']}>
            <Panel bordered>
                <Box padding="base">
                    <Row alignItems="center">
                        <Box margin={[0, 'small']}>
                            <Emblem
                                name={name}
                                logo={logo}
                                background={['aqua', 'dark']}
                                color={['gray', 'white']}
                            />
                        </Box>
                        <Box margin={[0, 0, 0, 'base']}>
                            <Row>
                                <Col>
                                    <Text size="larger" weight="bold">
                                        {name || 'Unknown Topic'}
                                    </Text>
                                </Col>
                            </Row>
                            <Row>
                                <Col>
                                    <Text weight="bold">
                                        {groupName || 'Unknown Group'}
                                    </Text>
                                </Col>
                            </Row>
                        </Box>
                    </Row>
                </Box>
            </Panel>
        </Box>
    );
};

const LogoGroupCard = ({ group }) => {
    const { name, logos } = group || {};
    const [
        hasMoreLogos,
        isLogosExpanded,
        visibleLogos,
        showMoreLogos,
        showLessLogos,
    ] = useLimitList(logos, 5);

    return (
        <Box margin={[0, 0, 'base']}>
            <Panel bordered>
                <Box margin="base">
                    <Row>
                        <Col>
                            <Text size="larger" weight="bold">
                                {name || 'Unknown Group'}
                            </Text>
                        </Col>
                    </Row>
                    <Box margin={['base', 0]}>
                        <Row gap="large" justifyContent="space-between">
                            {visibleLogos.map((l) => (
                                <Col span="auto">
                                    <img
                                        className={styles['logo-img-container']}
                                        src={l.image}
                                        alt="foo"
                                    />
                                </Col>
                            ))}
                        </Row>
                    </Box>
                    {hasMoreLogos && (
                        <Row>
                            <Col span="auto">
                                <Button
                                    theme="outline"
                                    size="small"
                                    onClick={showMoreLogos}
                                >
                                    Show All {logos.length} Brands
                                </Button>
                            </Col>
                        </Row>
                    )}
                    {isLogosExpanded && (
                        <Row>
                            <Col span="auto">
                                <Button
                                    theme="outline"
                                    size="small"
                                    onClick={showLessLogos}
                                >
                                    Show Fewer Brands
                                </Button>
                            </Col>
                        </Row>
                    )}
                </Box>
            </Panel>
        </Box>
    );
};

const VideoExploreBody = ({ loading, setLoading, errors, setErrors }) => {
    const { resource: activeUrl } = useParams();
    const history = useHistory();

    const [inputUrl, setInputUrl] = useState('');
    const [task, setTask] = useState({});
    const [intervalId, setIntervalId] = useState(0);
    const setTaskStart = useState()[1];
    const [topics, setTopics] = useState([]);
    const [logoGroups, setLogoGroups] = useState([]);

    const decodedActiveUrl = cleanUrl(decodeURIComponent(activeUrl || ''));

    const updateTask = async () => {
        let oldTask; // have to access the state this way, since updateTask is called inside setInterval
        let oldIntervalId;
        let oldTaskStart;
        setTask((t) => {
            oldTask = t;
            return t;
        });
        setIntervalId((i) => {
            oldIntervalId = i;
            return i;
        });
        setTaskStart((s) => {
            oldTaskStart = s;
            return s;
        });
        try {
            const newTask = await api().tasks.retrieve(oldTask.id);
            if (['Error', 'Done'].includes(newTask.state)) {
                setLoading(false);
                clearInterval(oldIntervalId);
                setIntervalId(0);
            } else if (Date.now() - oldTaskStart > TIMEOUT_LIMIT) {
                const error = {
                    timeout: "We've stopped processing your request",
                };
                throw error;
            }
            setTask(newTask);
        } catch (err) {
            if (err instanceof AbortError) return;
            setErrors(err);
            setLoading(false);
            clearInterval(oldIntervalId);
            setIntervalId(0);
        }
    };

    const createTask = async () => {
        try {
            setTask({});
            setErrors({});
            setLoading(true);
            setTaskStart(Date.now());
            const [
                newTask,
                { results: topicResults },
                { results: logoResults },
            ] = await Promise.all([
                api().tasks.create({
                    job: 'video_explore',
                    inputs: [
                        {
                            variable: 'url',
                            value: decodedActiveUrl,
                        },
                    ],
                }),
                api().topics.list({
                    verbose: true,
                    language: 'en',
                }),
                api().logoGroups.list({
                    verbose: true,
                }),
            ]);
            setTask(newTask);
            setTopics(topicResults);
            setLogoGroups(logoResults);
            setIntervalId(setInterval(updateTask, 2000));
        } catch (err) {
            if (err instanceof AbortError) return;
            setErrors(err);
            setLoading(false);
        }
    };

    const cancelLoading = () => {
        setTask({});
        setIntervalId(0);
        clearInterval(intervalId);
        setLoading(false);
    };

    useEffect(() => {
        if ((!inputUrl && !decodedActiveUrl) || inputUrl === decodedActiveUrl)
            return;

        if (loading) {
            api().abortAll();
            cancelLoading();
        } else {
            setTask({});
        }

        if (activeUrl && !task?.id) {
            if (!validUrl(cleanUrl(inputUrl))) {
                setErrors({ url: 'Please enter a valid URL.' });
            } else {
                setErrors({});
            }
        }
    }, [inputUrl]);

    useEffect(() => {
        if (!activeUrl) return null;

        // if the URL has changed, stop all current task retrieval
        clearInterval(intervalId);
        setIntervalId(0);

        setTask({});
        setErrors({});
        setLoading(false);

        // set the new URL;
        setInputUrl(decodedActiveUrl);

        // validity check
        if (!validUrl(decodedActiveUrl)) {
            setErrors({ url: 'Please enter a valid URL.' });
        } else {
            createTask();
        }

        return () => {
            clearInterval(intervalId);
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [activeUrl]);

    useEffect(() => {
        if (!validUrl(cleanUrl(decodedActiveUrl))) {
            history.replace('/discover/video/');
        }
        return () => {
            cancelLoading();
        };
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    const topicIds = JSON.parse(
        task.outputs?.find((i) => i.variable === 'topics')?.value || '[]',
    );
    const logoIds = JSON.parse(
        task.outputs?.find((i) => i.variable === 'logos')?.value || '[]',
    );
    const filteredLogoGroups = logoGroups.filter((lg) =>
        lg.logos.some((l) => logoIds.includes(l.id)),
    );
    const videoFilename = task.outputs?.find(
        (i) => i.variable === 'filename',
    )?.value;

    const constructTopicsRedirect = () => {
        const ruleSet = new RuleSet();
        const targetingRule = ruleSet.rules.find((r) => r.aggregation === 'OR');
        ruleSet.changeRule(targetingRule.key, { addTopics: topicIds });
        const newContext = { is_video: true, rules: ruleSet.rules };
        return createPresetUrl('/contexts/new/', newContext);
    };

    const constructLogosRedirect = () => {
        const flatLogos = filteredLogoGroups
            .map((lg) => lg.logos.map((l) => l.id))
            .flat();
        const ruleSet = new RuleSet();
        const targetingRule = ruleSet.rules.find((r) => r.aggregation === 'OR');
        ruleSet.changeRule(targetingRule.key, { addLogos: flatLogos });
        const newContext = { is_video: true, rules: ruleSet.rules };
        return createPresetUrl('/contexts/new/', newContext);
    };

    const isTimeoutError = Object.keys(errors).find((i) => i === 'timeout');
    const isTaskError = task.state === 'Error';

    const hardReload = () => window.location.reload(true);

    return (
        <>
            <ExploreForm
                inputUrl={inputUrl}
                processedUrl={decodedActiveUrl}
                setUrl={(u) => {
                    setInputUrl(u);
                    setErrors({});
                }}
                errorMessage={errors.url}
            >
                {!task.id && !loading && <StartMessage />}
                {loading && (
                    <Spinner
                        size="large"
                        message={<TaskLoadingMessage task={task} />}
                        color={['gray', 'dark']}
                        isCentered
                    />
                )}
                {isTaskError && !loading && (
                    <GenericErrorMessage
                        url={decodedActiveUrl}
                        onRetry={reloadPage}
                    />
                )}
                {isTimeoutError && !loading && (
                    <TimeoutAlertMessage onRetry={hardReload} />
                )}
            </ExploreForm>
            {!loading && videoFilename && (
                <Section hasBorderTop>
                    <Container>
                        <Box padding={['base', 0]}>
                            <SectionTitle info="View a preview of the video content we were able to extract from the URL">
                                Preview
                            </SectionTitle>
                            <Box margin={['base', 0]} borderRadius="rounder">
                                <Video
                                    src={videoFilename}
                                    squarePlayer
                                    style={{
                                        borderRadius: 12,
                                        height: 480,
                                    }}
                                />
                            </Box>
                        </Box>
                    </Container>
                </Section>
            )}
            {!loading && topicIds.length > 0 && (
                <Section hasBorderTop>
                    <Container>
                        <Box padding={['base', 0]}>
                            <SectionTitle
                                hasBar
                                info="See the topics 4D uses to categorize the video content"
                                extension={[
                                    <Button
                                        theme="outline"
                                        to={constructTopicsRedirect()}
                                    >
                                        Create Context
                                    </Button>,
                                ]}
                            >
                                Topics
                            </SectionTitle>
                            <Box margin={['base', 0, 0, 0]}>
                                <Row>
                                    {topics
                                        .filter((i) => topicIds.includes(i.id))
                                        .map((topic) => (
                                            <Col
                                                spanSm={12}
                                                spanMd={6}
                                                key={topic.id}
                                            >
                                                <TopicCard topic={topic} />
                                            </Col>
                                        ))}
                                </Row>
                            </Box>
                        </Box>
                    </Container>
                </Section>
            )}
            {!loading && filteredLogoGroups.length > 0 && (
                <Section hasBorderTop>
                    <Container>
                        <Box padding={['base', 0]}>
                            <SectionTitle
                                hasBar
                                info="4D has identified brands belonging to the following groups"
                                extension={[
                                    <Button
                                        theme="outline"
                                        to={constructLogosRedirect()}
                                    >
                                        Create Context
                                    </Button>,
                                ]}
                            >
                                Brand Groups
                            </SectionTitle>
                            <Box margin={['base', 0, 0, 0]}>
                                <Row>
                                    {filteredLogoGroups.map((group) => (
                                        <Col
                                            spanSm={12}
                                            spanMd={6}
                                            key={group.id}
                                        >
                                            <LogoGroupCard group={group} />
                                        </Col>
                                    ))}
                                </Row>
                            </Box>
                        </Box>
                    </Container>
                </Section>
            )}
        </>
    );
};

export default VideoExploreBody;
