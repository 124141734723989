import React from 'react';
import languageTypes from 'js/enums/language-types.enum';
import permissionGroups from 'js/enums/permission-groups.enum';
import ruleTypes from 'js/enums/rule-types.enum';
import sectionTypes from 'js/enums/section-types.enum';
import sortTypes from 'js/enums/sort-types.enum';
import Logo from 'media/images/logos/logo.svg';
import GoogleLogo from 'media/images/logos/dv360-logo.svg';
import TradeDeskLogo from 'media/images/logos/tradedesk-logo.png';
import XandrLogo from 'media/images/logos/xandr-logo.svg';
import AdformLogo from 'media/images/logos/adform-logo.svg';
import YoutubeLogo from 'media/images/logos/youtube.png';
import targetMetrics from 'js/pages/context-detail/components/optimize-section/enums/target-metrics.enum';

export const breakpointKeys = ['', 'Sm', 'Md', 'Lg', 'Xl'];

export const MIN_PASSWORD_LENGTH = 12;

export const permissions = [
    {
        label: 'Admin',
        value: permissionGroups.ADMIN,
        hint: (
            <p>
                The user can view, create, edit and delete items on this
                account, and manage account settings such as user access and
                channels.
            </p>
        ),
    },
    {
        label: 'Manager',
        value: permissionGroups.MANAGER,
        hint: (
            <p>
                The user can view, create, edit and delete items on this
                account.
            </p>
        ),
        pillTheme: 'blue',
    },
    {
        label: 'View-Only',
        value: permissionGroups.VIEW_ONLY,
        hint: <p>The user can view items on this account.</p>,
        pillOutline: true,
    },
];

export const SEARCH_DEBOUNCE_TIMEOUT = 700;
export const SEARCH_MIN_LENGTH = 2;

export const origins = [
    {
        id: sectionTypes.KEYWORDS_DIMENSION,
        name: 'Topics & Keywords',
        description:
            'Intelligently target and block content about certain topics or containing specific keywords using the 4D Knowledge Engine.',
        is_video: false,
        provider_name: '4D',
        provider_logo: Logo,
        cache_duration: Infinity,
        supported_languages: Object.values(languageTypes).filter(
            (item) => item,
        ),
        overviews: [
            {
                title: 'What is it ?',
                description:
                    '4D Keywords is a Dimension that allows you to build display contexts. ' +
                    'Execute broad or granular targeting by combining keywords with any combination of boolean logic. ',
            },
            {
                title: 'How it works',
                description:
                    '4D lets you use powerful boolean logic during context creation providing full transparency into the inventory you’re using and the targeting you’ll achieve. \n\n' +
                    'View Matched pages that your context matches as well as the high scoring keywords found on those pages to build contexts in real time. ',
            },
        ],
        about: {
            url: 'https://4d.silverbulletcloud.com/discover/page/', // TODO: make cluster specific?
            description:
                'Launched in 2020, 4D by Silverbullet Group is the only context insights engine providing next generation context+sentiment methodologies to drive in-the-moment marketing and brand suitability for digital media buying.\n\n' +
                'We’ve built our technology with the industry in mind and are ever-evolving to build an ecosystem for agency and brand marketers, publishers and targeting and analytics partners.',
        },
    },
    {
        id: sectionTypes.VIDEO_DIMENSION,
        name: 'Topics & Brands',
        description:
            'Target or block content that matches specific topics and brands using the 4D Vision Engine.',
        is_video: true,
        provider_name: '4D',
        provider_logo: Logo,
        cache_duration: Infinity,
        supported_languages: ['en'],
        overviews: [
            {
                title: 'What is it?',
                description:
                    '4D Topics & Brand Presence are Dimensions that allow you to build video contexts. ' +
                    'Enhance your Contexts by choosing to target or block from over 250 Topics using advanced boolean logic. ' +
                    'Further curate your Contexts by selecting from over 500+ brands.',
            },
            {
                title: 'How it works?',
                description:
                    "4D's technology uses the latest in Machine Learning, Natural Language Processing and Computer Vision to process and segment video content. \n\n" +
                    'Understand the holistic context of the video and target topics ranging from Auto to Gaming to Sports. ' +
                    'Tap into the 4D repository of brands to build contexts that either target or block at your discretion.',
            },
        ],
        about: {
            url: 'https://4d.silverbulletcloud.com/discover/video/', // TODO: make cluster specific?
            description:
                'Launched in 2020, 4D by Silverbullet Group is the only context insights engine providing next generation context+sentiment methodologies to drive in-the-moment marketing and brand suitability for digital media buying.\n\n' +
                'We’ve built our technology with the industry in mind and are ever-evolving to build an ecosystem for agency and brand marketers, publishers and targeting and analytics partners.',
        },
    },
];

export const contextTemplate = {
    name: '',
    advertiser: '',
    group: '',
    language: 'en',
    strategy: 'balanced',
    rules: [
        {
            key: 0,
            aggregation: ruleTypes.INCLUDED,
            keywords: [],
            topics: [],
            logos: [],
        },
        {
            key: 1,
            aggregation: ruleTypes.EXCLUDED,
            keywords: [],
            topics: [],
            logos: [],
        },
    ],
    channels: [],
    page_audits: [],
    video_audits: [],
    cpm_usd: '0.000',
    is_app: false,
    is_video: false,
};

export const dealTemplate = {
    id: null,
    channel: null,
    deal_id: '',
    deal_provider: null,
    deal_provider_type: null,
    is_active: true,
    bid_metadata: [],
    geotargets: [],
    rules: [{ aggregation: 'OR', contexts: [] }],
};

export const displayOptimizeTemplate = {
    context: null,
    is_active: false,
    target_metric: targetMetrics.CTR,
    controls: [
        {
            action: 'add',
            attribute: 'keywords',
            blacklisted_entities: [],
        },
        {
            action: 'remove',
            attribute: 'keywords',
            blacklisted_entities: [],
        },
        {
            action: 'add',
            attribute: 'topics',
            blacklisted_entities: [],
        },
        {
            action: 'remove',
            attribute: 'topics',
            blacklisted_entities: [],
        },
    ],
    recommendations: [],
    campaigns: [],
};

export const videoOptimizeTemplate = {
    context: null,
    is_active: false,
    target_metric: targetMetrics.VCR,
    controls: [
        {
            action: 'add',
            attribute: 'logos',
            blacklisted_entities: [],
        },
        {
            action: 'remove',
            attribute: 'logos',
            blacklisted_entities: [],
        },
        {
            action: 'add',
            attribute: 'topics',
            blacklisted_entities: [],
        },
        {
            action: 'remove',
            attribute: 'topics',
            blacklisted_entities: [],
        },
    ],
    recommendations: [],
    campaigns: [],
};

const knowledgeBase =
    'https://4d-silverbulletcloud.atlassian.net/wiki/spaces/4DKB/pages';

/* eslint-disable no-template-curly-in-string */
export const eventTagSources = [
    {
        id: 'youtube',
        label: 'YouTube (Google DV360)',
        campaignLabel: 'Insertion Order',
        lineItemLabel: 'Ad Group',
        availableForDisplay: false,
        logoImg: YoutubeLogo,
    },
    {
        id: 'youtube_gads',
        label: 'YouTube (Google Ads)',
        campaignLabel: 'Insertion Order',
        lineItemLabel: 'Ad Group',
        availableForDisplay: false,
        logoImg: YoutubeLogo,
    },
    {
        id: 'dv360',
        label: 'Google Display & Video 360',
        campaignLabel: 'Insertion Order',
        lineItemLabel: 'Line Item',
        availableForDisplay: true,
        videoUrlParams:
            'campaign-id=${CAMPAIGN_ID}&insertion-order-id=${INSERTION_ORDER_ID}&deal-id=${INVENTORY_SOURCE_EXTERNAL_ID}&creative-id=${CREATIVE_ID}&url=${SOURCE_URL}',
        scriptTags: [
            {
                label: null,
                script: '<script src="https://4d.silverbulletcloud.com/media/analytics/tag.min.js" data-source-id="dv360" data-avocado-id="<account_id>" data-campaign-id="${CAMPAIGN_ID}" data-insertion-order-id="${INSERTION_ORDER_ID}" data-url="${SOURCE_URL}" data-deal-id="${INVENTORY_SOURCE_EXTERNAL_ID}" data-creative-id="${CREATIVE_ID}"></script>',
            },
        ],
        logoImg: GoogleLogo,
        noticeMsg: null,
        knowledgeBaseLink: `${knowledgeBase}/2134114314/DV360+-+How+to+Activate+4D+in+Display+Video+360`,
        description: (
            <>
                <div>
                    <h3>
                        If the creative is hosted by&nbsp;Display & Video
                        360&nbsp;or synced from&nbsp;Campaign Manager 360
                    </h3>
                    <ol>
                        <li>Open an advertiser, click&nbsp;Creatives.</li>
                        <li>
                            Click on a creative&apos;s&nbsp;name to edit that
                            creative&apos;s details.
                        </li>
                        <li>
                            Find and click&nbsp;Additional details&nbsp;to
                            expand the section.
                        </li>
                        <li>
                            Check the &ldquo;Append HTML tracking tag&rdquo;
                            checkbox. If there&apos;s no &ldquo;Append
                            Tag&rdquo; option for your creative,&nbsp;the
                            feature is&nbsp;not enabled for your partner. To
                            enable it,&nbsp;contact&nbsp;support.
                        </li>
                        <li>Paste&nbsp;the 4D Analytic tag&nbsp;to append.</li>
                        <li>Click&nbsp;Save.</li>
                    </ol>
                </div>

                <div>
                    <h3>
                        If the creative is hosted by a third-party ad server
                    </h3>
                    <ol>
                        <li>Open an advertiser, then click&nbsp;Creatives.</li>
                        <li>
                            Click on a creative&apos;s name to edit that
                            creative&apos;s details.
                        </li>
                        <li>
                            You&apos;ll see the full creative tag in the
                            Third-party tag field. Below the creative tag,
                            paste&nbsp;the HTML tags to append.
                        </li>
                        <li>
                            To make sure the tag is working, click&nbsp;Test
                            tag. If the tag is recognized by Display & Video
                            360, you&apos;ll see a checkmark and the message:
                            &ldquo;Tag recognized&rdquo;.
                        </li>
                        <li>Click&nbsp;Save.</li>
                    </ol>
                </div>
            </>
        ),
        eventTrackingHTML: (
            <ol>
                <li>Open an advertiser, then click Creatives.</li>
                <li>
                    Click on a creative&apos;s name to edit that creative&apos;s
                    details.
                </li>
                <li>
                    Scroll to Serving properties section of the creative page.
                </li>
                <li>Click on the Add URL.</li>
                <li>
                    Select each event you want to track and paste the relative
                    tracking code from 4D.
                </li>
                <li>Click&nbsp;Save.</li>
            </ol>
        ),
        eventTrackingOptions: [
            {
                value: 3,
                label: 'Impression',
            },
            {
                value: 0,
                label: 'Click Tracking',
            },
            {
                value: 7,
                label: 'Start',
            },
            {
                value: 9,
                label: 'First Quartile',
            },
            {
                value: 8,
                label: 'Midpoint',
            },
            {
                value: 10,
                label: 'Third Quartile',
            },
            {
                value: 11,
                label: 'Complete',
            },
        ],
    },
    {
        id: 'ttd',
        label: 'The Trade Desk',
        campaignLabel: 'Campaign',
        lineItemLabel: 'Ad Group',
        availableForDisplay: true,
        videoUrlParams:
            'campaign-id=%%TTD_CAMPAIGNID%%&adgroup-id=%%TTD_ADGROUPID%%&deal-id=%%TTD_DEALID%%&creative-id=%%TTD_CREATIVEID%%&url=%%TTD_SITE_WITH_PATH%%',
        scriptTags: [
            {
                label: null,
                script: '<script src="https://4d.silverbulletcloud.com/media/analytics/tag.min.js" data-source-id="ttd" data-avocado-id="<account_id>" data-campaign-id="%%TTD_CAMPAIGNID%%" data-adgroup-id="%%TTD_ADGROUPID%%" data-url="%%TTD_SITE_WITH_PATH%%" data-deal-id="%%TTD_DEALID%%" data-creative-id="%%TTD_CREATIVEID%%"></script>',
            },
        ],
        logoImg: TradeDeskLogo,
        noticeMsg: null,
        knowledgeBaseLink: `${knowledgeBase}/2134769665/TTD+-+Activating+4D+in+The+Trade+Desk`,
        description: (
            <div>
                <h3>Quickstart Guide</h3>
                <ol>
                    <li>
                        Open an advertiser, click the libraries drop-down menu &
                        select Creatives.
                    </li>
                    <li>
                        Click on a creative&apos;s&nbsp;name to edit that
                        creative&apos;s details.
                    </li>
                    <li>
                        Scroll to the Tracking section of the creative page.
                    </li>
                    <li>
                        Paste the 4D analytic tag in the box labelled JavaScript
                        Tracking Tag.
                    </li>
                    <li>Click&nbsp;Save.</li>
                </ol>
            </div>
        ),
        eventTrackingHTML: (
            <ol>
                <li>
                    Open an advertiser, click the libraries drop-down menu &
                    select Creatives.
                </li>
                <li>
                    Click on a creative&apos;s name to edit that creative&apos;s
                    details.
                </li>
                <li>Scroll to the Tracking section of the creative page.</li>
                <li>Click on the “+ Add Event Tracking URL”.</li>
                <li>
                    Select each Location you want to track and paste the
                    relative tracking code from 4D.
                </li>
                <li>Click&nbsp;Save.</li>
            </ol>
        ),
        eventTrackingOptions: [
            {
                value: 3,
                label: 'Impression',
            },
            {
                value: 0,
                label: 'Click',
            },
            {
                value: 7,
                label: 'Start',
            },
            {
                value: 9,
                label: 'First Quartile',
            },
            {
                value: 8,
                label: 'Midpoint',
            },
            {
                value: 10,
                label: 'Third Quartile',
            },
            {
                value: 11,
                label: 'Complete',
            },
        ],
    },
    {
        id: 'xandr',
        label: 'Xandr',
        campaignLabel: 'Campaign',
        lineItemLabel: 'Line Item',
        availableForDisplay: true,
        videoUrlParams:
            'campaign-id=${CP_ID}&line-item-id=${CPG_ID}&insertion-order-id=${IO_ID}&deal-id=${DEAL_ID}&creative-id=${CREATIVE_ID}&url=${REFERER_URL_ENC}',
        scriptTags: [
            {
                label: null,
                script: '<script src="https://4d.silverbulletcloud.com/media/analytics/tag.min.js" data-source-id="xandr" data-avocado-id="<account_id>" data-campaign-id="${CP_ID}" data-line-item-id="${CPG_ID}" data-insertion-order-id="${IO_ID}" data-url="${REFERER_URL_ENC}" data-deal-id="${DEAL_ID}" data-creative-id="${CREATIVE_ID}"></script>',
            },
        ],
        logoImg: XandrLogo,
        noticeMsg: null,
        description: (
            <div>
                <h3>Quickstart Guide</h3>
                <ol>
                    <li>
                        To get started with 4D insights, please apply the 4D tag
                        directly to creative units within your DSP.
                    </li>
                    <li>
                        For any questions, please reach out to your DSP account
                        manager.
                    </li>
                </ol>
            </div>
        ),
        eventTrackingHTML: (
            <ol>
                <li>Open a creative, then look at the Pixels section.</li>
                <li>
                    Under Third-Party Tracking Pixels for Creatives, add the
                    desired event URLs.
                </li>
                <li>Click&nbsp;Save.</li>
            </ol>
        ),
        eventTrackingOptions: [
            {
                value: 3,
                label: 'Impression',
            },
            {
                value: 0,
                label: 'Click',
            },
            {
                value: 7,
                label: '0% (Start)',
            },
            {
                value: 9,
                label: '25% Complete',
            },
            {
                value: 8,
                label: '50% Complete',
            },
            {
                value: 10,
                label: '75% Complete',
            },
            {
                value: 11,
                label: '100% Complete',
            },
        ],
    },
    {
        id: 'adform',
        label: 'Adform',
        campaignLabel: 'Campaign',
        lineItemLabel: 'Line Item',
        availableForDisplay: true,
        scriptTags: [
            {
                label: 'Impression Pixel',
                script: 'https://ptingless.4d.silverbulletcloud.com/metrics/?source-id=adform&avocado-id=<account_id>&campaign-id=__ADFCAMID__&line-item-id=__ADFPLAID__&url=__ADFREFERER__&deal-id=__ADFDPID__&creative-id=__ADFCID__&event_type=3',
            },
            {
                label: 'Click Pixel',
                script: 'https://ptingless.4d.silverbulletcloud.com/metrics/?source-id=adform&avocado-id=<account_id>&campaign-id=__ADFCAMID__&line-item-id=__ADFPLAID__&url=__ADFREFERER__&deal-id=__ADFDPID__&creative-id=__ADFCID__&event_type=0',
            },
        ],
        logoImg: AdformLogo,
        noticeMsg:
            'Before being able to use the 4D Tag, you will have to reach out to Adform Support and whitelist the ‘http://4d.silverbulletcloud.com’ domain for your account.',
        knowledgeBaseLink: `${knowledgeBase}/2134147073/Adform+-+How+to+Activate+4D+on+Adform`,
        description: (
            <div>
                <h3>Quickstart Guide</h3>
                <ol>
                    <li>
                        Open an advertiser, click the Creatives drop-down menu &
                        select Banners.
                    </li>
                    <li>Navigate to the Tags Page.</li>
                    <li>
                        Select the tags you would like to apply the 4D Tag to by
                        selecting the check box.
                    </li>
                    <li>Click Tagging from the menu that will appear.</li>
                    <li>
                        Paste the 4D Tags into the box labelled Imp Pixel and
                        Click Pixel.{' '}
                    </li>
                    <li>Click Save.</li>
                </ol>
            </div>
        ),
    },
];
/* eslint-enable no-template-curly-in-string */

export const INITIALLY_VISIBLE_TOPICS = 14;
export const INITIALLY_VISIBLE_LOGOS = 14;
export const INITIALLY_VISIBLE_KEYWORDS = 14;

export const languageOptions = [
    {
        label: 'Any',
        value: languageTypes.ANY,
        group: 0,
    },
    {
        label: 'Arabic',
        value: languageTypes.AR,
        group: 0,
    },
    {
        label: 'Chinese (Simplified)',
        value: languageTypes.ZH_CN,
        group: 0,
    },
    {
        label: 'Chinese (Traditional)',
        value: languageTypes.ZH_TW,
        group: 0,
    },
    {
        label: 'English',
        value: languageTypes.EN,
        group: 0,
    },
    {
        label: 'French',
        value: languageTypes.FR,
        group: 0,
    },
    {
        label: 'German',
        value: languageTypes.DE,
        group: 0,
    },
    {
        label: 'Italian',
        value: languageTypes.IT,
        group: 0,
    },
    {
        label: 'Japanese',
        value: languageTypes.JA,
        group: 0,
    },
    {
        label: 'Portuguese',
        value: languageTypes.PT,
        group: 0,
    },
    {
        label: 'Spanish',
        value: languageTypes.ES,
        group: 0,
    },
    {
        label: 'Swedish',
        value: languageTypes.SV,
        group: 0,
    },
];

export const unsupportedYoutubeLangs = [
    languageTypes.AR,
    languageTypes.JA,
    languageTypes.ZH_CN,
    languageTypes.ZH_TW,
];

export const sortOptions = [
    {
        label: 'Recently Updated First',
        value: sortTypes.UPDATED_DESC,
        group: 0,
    },
    {
        label: 'Recently Updated Last',
        value: sortTypes.UPDATED_ASC,
        group: 0,
    },
    {
        label: 'Name (A to Z)',
        value: sortTypes.NAME_ASC,
        group: 1,
    },
    {
        label: 'Name (Z to A)',
        value: sortTypes.NAME_DESC,
        group: 1,
    },
];

export const accountMgmtEmailAddress = '4Daccountmanagement@weare4d.com';

export const knowledgeBaseYoutubeFileLink = `${knowledgeBase}/2450948098/Youtube+report+creation+via+file+upload`;

export const daysOfTheWeek = ['mon', 'tue', 'wed', 'thu', 'fri', 'sat', 'sun'];
