import React, { useRef, useEffect, useState } from 'react';
import PropTypes from 'prop-types';

function Bubble({ data, color, onClick, onMouseEnter, onMouseLeave }) {
    const defaultTextSize = 30; // TODO: move to prop?
    const el = useRef();
    const [fontSize, setFontSize] = useState(defaultTextSize);
    const [opacity, setOpacity] = useState(0);

    useEffect(() => {
        /*
            We do this so that the text of a bubble is contained within it.
        */
        setFontSize(
            Math.max(
                Math.min(
                    defaultTextSize,
                    ((2 * data.r - 8) /
                        Math.max(
                            ...Array.from(el.current.children).map((c) =>
                                c.getComputedTextLength(),
                            ),
                        )) *
                        24,
                    data.r / el.current.children.length,
                ),
                0,
            ),
        );
        /*
            We set opacity to avoid a flash of large text.
        */
        setOpacity(1);
    }, [el, defaultTextSize, data.r]);

    return (
        <>
            <circle
                r={data.r}
                transform={`translate(${data.x}, ${data.y})`}
                fill={color}
                style={{
                    ...data.style,
                    ...(onClick ? { cursor: 'pointer' } : {}),
                }}
                onClick={onClick && ((e) => onClick(e, data))}
                onMouseEnter={onMouseEnter && ((e) => onMouseEnter(e, data))}
                onMouseLeave={onMouseLeave && ((e) => onMouseLeave(e, data))}
            />
            <text
                textAnchor="middle"
                dominantBaseline="middle"
                ref={el}
                transform={`translate(${data.x}, ${data.y})`}
                pointerEvents="none"
                style={{
                    fontVariant: 'normal',
                    fontWeight: 'bold',
                    fontFamily: 'Arial',
                    fontSize: `${fontSize}px`,
                    opacity,
                }}
                fill="#FFFFFF"
            >
                {data.label.split(' ').map((line, index) => (
                    <tspan
                        key={line}
                        x="0"
                        dy={
                            fontSize *
                            1.2 *
                            (!!index +
                                !index *
                                    (0.5 - data.label.split(' ').length / 2))
                        } // Offset each line
                        style={{ userSelect: 'none' }}
                    >
                        {line}
                    </tspan>
                ))}
            </text>
        </>
    );
}

Bubble.defaultProps = {
    onClick: undefined,
    onMouseEnter: () => {},
    onMouseLeave: () => {},
};

Bubble.propTypes = {
    data: PropTypes.object.isRequired,
    onClick: PropTypes.func,
    onMouseEnter: PropTypes.func,
    onMouseLeave: PropTypes.func,
};

export default Bubble;
